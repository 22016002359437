export const ErpInvoiceType = Object.freeze({
  9: "common.invoiceHousingCertificate", // New translation added and currently in use
  10: "common.invoiceEnergyCertificate", // New translation added and currently in use
  32: "common.rentalsReservationInvoiced",
  33: "common.rentalsReservationCancelledOwnerResponsability",
  34: "common.rentalsReservationCancelledTenantResponsability",
  36: "common.rentalsOwnerPaymentInvoiced",
  "36-income": "common.rentalsOwnerPaymentInvoicedIncome", // New translation added and currently in use
  "36-outcome": "common.rentalsOwnerPaymentInvoicedOutcome", // New translation added and currently in use
  49: "common.creditNoteHousingCertificatePartialPayment", // New translation added and currently in use
  50: "common.creditNoteEnergyCertificatePartialPayment", // New translation added and currently in use
  71: "common.creditNoteRentalsReservationInvoiced",
  72: "common.creditNoteRentalsReservationCancelledOwnerResponsibility",
  73: "common.creditNoteRentalsReservationCancelledTenantResponsibility",
  74: "common.creditNoteRentalsOwnerPaymentInvoiced",
  83: "common.manualInvoiceRentals", // New translation added and currently in use
  84: "common.creditNoteManualInvoiceRentals", // New translation added and currently in use
  91: "common.rentalTenantInvoice",
  94: "common.rentalsDeposit",
  95: "common.rentalSupplyManagement",
  96: "common.rentalTenantChargeRemittance",
  97: "common.rentalIrregulars",
  101: "common.rentalsItpManagement",
  102: "common.creditNoteRentalsItpManagement",
  103: "common.remittanceManagementFee",
  104: "common.remittanceManagementFeeCreditNote",
});
